// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-40-million-gift-faqs-js": () => import("./../../../src/pages/40-million-gift-faqs.js" /* webpackChunkName: "component---src-pages-40-million-gift-faqs-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-airwaves-js": () => import("./../../../src/pages/airwaves.js" /* webpackChunkName: "component---src-pages-airwaves-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cosmos-js": () => import("./../../../src/pages/cosmos.js" /* webpackChunkName: "component---src-pages-cosmos-js" */),
  "component---src-pages-empowerment-js": () => import("./../../../src/pages/empowerment.js" /* webpackChunkName: "component---src-pages-empowerment-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-expression-js": () => import("./../../../src/pages/expression.js" /* webpackChunkName: "component---src-pages-expression-js" */),
  "component---src-pages-frontline-js": () => import("./../../../src/pages/frontline.js" /* webpackChunkName: "component---src-pages-frontline-js" */),
  "component---src-pages-holiday-update-js": () => import("./../../../src/pages/holiday-update.js" /* webpackChunkName: "component---src-pages-holiday-update-js" */),
  "component---src-pages-hope-js": () => import("./../../../src/pages/hope.js" /* webpackChunkName: "component---src-pages-hope-js" */),
  "component---src-pages-impact-js": () => import("./../../../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-opportunities-js": () => import("./../../../src/pages/investment-opportunities.js" /* webpackChunkName: "component---src-pages-investment-opportunities-js" */),
  "component---src-pages-newsletter-winter-2021-js": () => import("./../../../src/pages/newsletter/winter-2021.js" /* webpackChunkName: "component---src-pages-newsletter-winter-2021-js" */),
  "component---src-pages-purpose-js": () => import("./../../../src/pages/purpose.js" /* webpackChunkName: "component---src-pages-purpose-js" */),
  "component---src-pages-rescue-js": () => import("./../../../src/pages/rescue.js" /* webpackChunkName: "component---src-pages-rescue-js" */),
  "component---src-pages-resilience-js": () => import("./../../../src/pages/resilience.js" /* webpackChunkName: "component---src-pages-resilience-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-tassel-js": () => import("./../../../src/pages/tassel.js" /* webpackChunkName: "component---src-pages-tassel-js" */),
  "component---src-pages-tides-js": () => import("./../../../src/pages/tides.js" /* webpackChunkName: "component---src-pages-tides-js" */),
  "component---src-pages-trade-js": () => import("./../../../src/pages/trade.js" /* webpackChunkName: "component---src-pages-trade-js" */),
  "component---src-pages-twitch-js": () => import("./../../../src/pages/twitch.js" /* webpackChunkName: "component---src-pages-twitch-js" */),
  "component---src-pages-waves-js": () => import("./../../../src/pages/waves.js" /* webpackChunkName: "component---src-pages-waves-js" */),
  "component---src-pages-why-i-give-js": () => import("./../../../src/pages/why-i-give.js" /* webpackChunkName: "component---src-pages-why-i-give-js" */),
  "component---src-pages-winds-js": () => import("./../../../src/pages/winds.js" /* webpackChunkName: "component---src-pages-winds-js" */),
  "component---src-pages-zoom-js": () => import("./../../../src/pages/zoom.js" /* webpackChunkName: "component---src-pages-zoom-js" */),
  "component---src-templates-areas-template-js": () => import("./../../../src/templates/areasTemplate.js" /* webpackChunkName: "component---src-templates-areas-template-js" */),
  "component---src-templates-colleges-template-js": () => import("./../../../src/templates/collegesTemplate.js" /* webpackChunkName: "component---src-templates-colleges-template-js" */),
  "component---src-templates-digital-ads-landing-page-tempalte-js": () => import("./../../../src/templates/digitalAdsLandingPageTempalte.js" /* webpackChunkName: "component---src-templates-digital-ads-landing-page-tempalte-js" */),
  "component---src-templates-impact-stories-js": () => import("./../../../src/templates/impact-stories.js" /* webpackChunkName: "component---src-templates-impact-stories-js" */),
  "component---src-templates-themes-template-js": () => import("./../../../src/templates/themesTemplate.js" /* webpackChunkName: "component---src-templates-themes-template-js" */)
}

